
export const CODE_SIGN_IN = {
  USER_INCORRECT_PASSWORD: 'NotAuthorizedException',
  USER_NOT_FOUND : 'UserNotFoundException',
  USER_NOT_VERIFIED: "UserNotConfirmedException",
  USER_BLOCKED : "UserBlocked",
  USER_LAMBDA_BLOCKED : "UserLambdaValidationException"
}

export const CODE_SIGN_UP = {
  USER_ROLE : 'developer',
  USER_EXISTS : "UsernameExistsException",
  ATTRIBUTE_ERROR : "InvalidParameterException"

}

export const CODE_COMMON = {
  COGNITO_INTERNAL_ERROR : "InternalErrorException",

}

export const CODE_COGNITO = {
  PROCESS_VALID : true,
  PROCESS_INVALID : false,
  UNKNOWN_ERROR : "UnknownError",
  USER_NOT_AUTHORIZED : "NotAuthorizedException",
  NETWORK_ERROR : "NetworkError",
  USER_NOT_FOUND : 'UserNotFoundException',
  PROCESS_LIMIT : "LimitExceededException",

}


export const CODE_RESEND = {
  USER_NOT_VERIFIED : "InvalidParameterException",
  USER_LIMIT_EXCEED : "LimitExceededException",
  USER_NOT_FOUND : 'UserNotFoundException',
  LAMBDA_ERROR : "UserLambdaValidationException"

}

export const CODE_RESET = {
  RESET_EXPIRED : "ExpiredCodeException",
  RESET_MISMATCH : "CodeMismatchException",
  RESET_PASSWORD : "ExistingPassword",
  RESET_INVALID : "InvalidParameterException",
  RESET_INCORRECT : "NotAuthorizedException"
}

export const CODE_API = {

}

export const ERROR_CODE = {
  E0x10002 : "LAMBDA_ERR",
  E0x10001 : "RESEND_ERR"

}
