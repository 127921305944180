import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Footer from '../components/footer'

import '../components/styles/layout.css'
import '../components/styles/bootstrap.min.css'
import '../components/styles/vcomposer.css'

import '../components/styles/global-elements-1e25dfed1354ac6094720f7ce2e0e08a.css'
import '../components/styles/style.min.css'
import '../components/styles/custom.css'
import { DefaultNav } from './navbar';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title,
          }
        }
      }
    `}
    render={data => (
      <>
        {/* Set site MetaData */}
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'NETS Developer Portal' },
            { name: 'keywords', content: 'nets, developer, api' },
          ]}
        >
          <html lang="en" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css" integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/" crossorigin="anonymous" />
        </Helmet>
        <DefaultNav/>
          {children}
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
