import React from 'react'

let year = new Date().getFullYear()
const Footer = () => (
    <div
        style={{
            background: 'rgb(51,52,53)',
            color: '#f0f0f0',
            borderTop: "1px solid #f0eeee",
            padding: '0.3rem 1.0875rem 0.1rem',
            position: 'relative',
            bottom: '0',
            left: '0',
            right: '0',
            fontSize : '0.8rem'
        }}
        className="clearfix"
    >
        <small className="clearfix">
            <i className="float-right">
                Best viewed on chrome &nbsp;
                <font style={{color:`rgba(250,250,250, 0.8)`}}>
                    (v. 071920191506)
                </font>
            </i>
        </small>
        {/* DESKTOP */}
        <p className="d-md-block d-none clearfix" style={{ padding : `10px 0 0` }}>
            <font className="float-left" >
                © {year} Network for Electronic Transfers ( Singapore ) Pte. Ltd. 
            </font>
            <font className="float-right" >
                <a href="https://www.nets.com.sg/policies/security" target="_blank" rel="noopener noreferrer" style={{ color : "#fff"}}>
                    Security
                </a>
                {` | `}
                <a href="https://www.nets.com.sg/policies/terms-of-use" target="_blank" rel="noopener noreferrer" style={{ color : "#fff"}}>
                    Terms of Use
                </a>
                {` | `}
                <a href="https://www.nets.com.sg/policies/data-protection" target="_blank" rel="noopener noreferrer" style={{ color : "#fff"}}>
                    Data Protection Policy
                </a>
            </font>
        </p>
        {/* MOBILE */}
        <p className="d-md-none text-center" style={{ margin : `auto` }}>
            © {year} Network for Electronic Transfers ( Singapore ) Pte. Ltd. <br />
            <a href="https://www.nets.com.sg/policies/security" target="_blank" rel="noopener noreferrer" style={{ color : "#fff"}}>
                Security
            </a>
            {` | `}
            <a href="https://www.nets.com.sg/policies/terms-of-use" target="_blank" rel="noopener noreferrer" style={{ color : "#fff"}}>
                Terms of Use
            </a>
            {` | `}
            <a href="https://www.nets.com.sg/policies/data-protection" target="_blank" rel="noopener noreferrer" style={{ color : "#fff"}}>
                Data Protection Policy
            </a>
        </p>
        
    </div>
)

export default Footer
