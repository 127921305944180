import { Cache, API } from "aws-amplify";
import { navigate } from "@reach/router";
// import { CODE_API } from '../components/authcode'
import md5 from 'md5'
import crypto from "crypto";


export const isBrowser = () => typeof window !== "undefined"


// --------------------
// | CMS FUNCTIONS |
// --------------------

export const handleCmsFile = async (data) => {

    let result

    let init = {
        body: {
            stage_file: data.stage_file,
        }
    }

    var endpoint = `${process.env.API_ENV}/cms/get`

    await API.post('rdsApi', endpoint, init)
        .then((data) => {
            console.log(data)
            result = data
        })
        .catch((err) => {
            console.log(err);
            result = err
        })

    return result
}

// --------------------
// | MAILER FUNCTIONS |
// --------------------

export const handleRefer = async (data) => {

    let result
    let emailRecipients = [
        data.email
    ]

    let init = {
        body: {
            email: data.email,
            referee: data.referee,
            name: data.name,
            message: data.message,
            captcha: data.captcha
        }
    }

    var endpoint = `${process.env.API_ENV}/refer/submit`

    let res = await API.post('rdsApi', endpoint, init)
        .then((data) => {
            console.log(data)
            result = data
        })
        .catch((err) => {
            console.log(err);
            result = err
        })

    return res
}

export const handleEnquiry = async (data) => {

    let flag = false;
    const ticketNo = Date.now()

    let init = {
        body: {
            enquiry: data.enquiry,
            name: data.name,
            email: data.email,
            description: data.description,
            sendTo: 'admin',
            ticketNo: ticketNo,
            captcha: data.captcha
        }
    }

    var endpoint = `${process.env.API_ENV}/enquiry/submit`

    await API.post('privateApi', endpoint, init)
        .then(async (res) => {
            let init = {
                body: {
                    enquiry: data.enquiry,
                    name: data.name,
                    email: data.email,
                    description: data.description,
                    sendTo: 'public',
                    ticketNo: ticketNo,
                    captcha: data.captcha
                }
            }

            flag = true

        })
        .catch(err => {
            console.log('here 2');
            console.log(err)
            return false
        })

    return flag
}

export const handleGetEnquiries = async (data) => {
    let result
    let init = {
        queryStringParameters: {
            t: "status",
            q: "active"
        }
    }

    var endpoint = `${process.env.API_ENV}/enquiry/get`

    await API.get('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}


// -------------------
// | USER FUNCTIONS |
// -------------------

export const handleBlock = async (data) => {

    let init = {
        body: {
            "email": data.toLowerCase()
        }
    }

    var endpoint = `${process.env.API_ENV}/user/block`

    await API.post('rdsApi', endpoint, init)
        .then(() => {

        })
        .catch(err => console.log(err))

    return
}


export const handleUnblock = async (data) => {

    let init = {
        body: {
            "email": data
        }
    }

    var endpoint = `${process.env.API_ENV}/user/unblock`

    await API.post('rdsApi', endpoint, init)
        .then(() => {

        })
        .catch(err => console.log(err))

    return
}

export const handleBlockState = async (data) => {

    let result
    let init = {
        queryStringParameters: {
            username: data.username
        }
    }

    var endpoint = `${process.env.API_ENV}/state`

    await API.get('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

// -------------------
// | FORUM FUNCTIONS |
// -------------------
export const handleGetForumCategories = async (data) => {
    let result
    let init = {
        queryStringParameters: {
            t: "status",
            q: "active"
        }
    }

    var endpoint = `${process.env.API_ENV}/forum/category`

    await API.get('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

export const handleSubmitForumPost = async (data) => {

    const dateTime = Date.now();

    let result
    let init = {
        body: {
            author_id: data.id,
            author_name: data.name,
            title: data.title,
            content: data.description,
            tags: JSON.stringify(data.tags),
            category: data.category.category_id,
            status: data.status,
            email: data.email,
            type: "post",
            post_id: dateTime,
            captcha: data.captcha
        }
    }

    var endpoint = `${process.env.API_ENV}/forum`

    console.log(init)
    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

export const handleGetForumPosts = async (data) => {

    let result
    let init = {
        queryStringParameters: {
            type: "post"
        }
    }

    var endpoint = `${process.env.API_ENV}/forum`

    await API.get('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err.response.data))

    return result
}


export const handleGetForumTags = async (data) => {

    let result
    let init = {
        queryStringParameters: {
            type: "tag"
        }
    }

    var endpoint = `${process.env.API_ENV}/forum`

    await API.get('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err.response.data))

    return result
}

export const handleGetForumPostDetails = async (data) => {

    let result
    let init = {
        queryStringParameters: {
            type: "post",
            post_id: data.id
        }
    }

    var endpoint = `${process.env.API_ENV}/forum`

    await API.get('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err.response.data))

    return result
}

export const handleGetForumComments = async (data) => {

    let result
    let init = {
        queryStringParameters: {
            type: "comment",
            post_id: data.id
        }
    }

    var endpoint = `${process.env.API_ENV}/forum`

    await API.get('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

export const handleCommentSubmit = async (data) => {
    console.log(data)
    let result
    let init = {
        body: {
            post_id: data.id,
            content: data.description,
            author_id: data.username,
            author_name: data.name,
            email: data.email,
            type: "comment",
            title: data.title
        }
    }

    var endpoint = `${process.env.API_ENV}/forum`

    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}


// TODO: Create event handler at Lambda
export const handleCommentDelete = async (data) => {

    let result
    let init = {
        queryStringParameters: {
            id: data.id,
            comment_id: data.comment_id
        }
    }

    var endpoint = `${process.env.API_ENV}/forum`

    await API.del('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

export const handlePostClose = async (data) => {

    let result
    let init = {
        queryStringParameters: {
            post_id: data.id,
        }
    }

    var endpoint = `${process.env.API_ENV}/forum`

    await API.put('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

// -------------------------
// | APPLICATION FUNCTIONS |
// -------------------------

export const handleSandboxApplicationCreation = async (data) => {
    const dateTime = Date.now();

    let result
    let init = {
        body: {
            api: data.api,
            corsOrigin: ["*"],
            key: md5(data.key + dateTime),
            secret: md5(data.secret + dateTime),
            name: escapeRegExp(data.name),
            state: "enabled",
            type: data.type,
            username: data.id,
            createdBy: data.username,
            email: data.email,
            token: Cache.getItem("otpToken")
        }
    }

    var endpoint = `${process.env.API_ENV}/app`

    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => processAppAPIError(err, true))

    return result
}

export const handleProductionApplicationCreation = async (data) => {
    const dateTime = Date.now();

    let result
    let init = {
        body: {
            api: JSON.stringify({ api: data.api, country: data.country, contact: data.contact, key: null, apiname: data.apiname }),
            corsOrigin: ["*"],
            key: crypto.createHash('sha256').update(data.key + dateTime).digest('hex'),
            secret: crypto.createHash('sha256').update(data.secret + dateTime).digest('hex'),
            name: data.name,
            state: "enabled",
            type: data.type,
            username: data.id,
            createdBy: data.username,
            email: data.email,
            merchant: data.merchant,
            organization: data.organization,
            token: Cache.getItem("otpToken")
        }
    }

    var endpoint = `${process.env.API_ENV}/app`

    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => processAppAPIError(err, true))

    return result
}


export const handleApplicationUpdate = async (data) => {

    let result
    let init = {
        body: {
            _id: data.appId,
            api: data.apiArray
        }
    }

    var endpoint = `${process.env.API_ENV}/app`

    await API.put('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

// TODO: Switch this to POST Method
export const handleGetSandboxApplication = async (data) => {

    let result
    let init = {
        body: {
            username: data.username,
            type: "app",
            environment: "sandbox",
            token: Cache.getItem("otpToken")
        }
    }

    var endpoint = `${process.env.API_ENV}/app/get`

    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch((err) => processAppAPIError(err))

    return result
}

export const handleGetProductionApplication = async (data) => {
    let result
    let init = {
        body: {
            username: data.username,
            type: "app",
            environment: "production",
            email: data.email,
            token: Cache.getItem("otpToken")
        }
    }

    var endpoint = `${process.env.API_ENV}/app/get`

    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => processAppAPIError(err))

    return result
}

export const handleGetRequestApplication = async (data) => {
    let result
    let init = {
        body: {
            username: data.username,
            token: Cache.getItem("otpToken")
        }
    }

    var endpoint = `${process.env.API_ENV}/app/request`

    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => processAppAPIError(err))
    return result
}

// TODO: Switch this to POST Method
// DEPRECATED 
export const handleGetProductionApplicationDetails = async (data) => {
    let result
    let init = {
        queryStringParameters: {
            username: data.username,
            type: "app",
            environment: "production",
            email: data.selfEmail,
            id: data.data.application_id
        }
    }

    var endpoint = `${process.env.API_ENV}/app`

    await API.get('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

// TODO: Switch this to POST Method
// DEPRECATED
export const handleGetProductionApplicationSecret = async (data) => {
    console.log(data)
    let result
    let init = {
        queryStringParameters: {
            username: data.username,
            email: data.email,
            id: data.id.app_id,
            type: "app",
            environment: "production",
            detail: "secret"
        }
    }

    var endpoint = `${process.env.API_ENV}/app`

    await API.get('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

// DELETE SANDBOX APPLICATION
export const handleSandboxDeleteApplication = async (data) => {

    let result
    let init = {
        body: {
            _id: data.appId,
            remarks: "Deleted by " + data.name,
            token: Cache.getItem("otpToken")
        }
    }

    var endpoint = `${process.env.API_ENV}/app/sandbox`

    await API.del('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => processAppAPIError(err))

    return result
}

// DELETE PRODUCTION APPLICATION
export const handleProductionDeleteApplication = async (data) => {

    let result
    let init = {
        body: {
            _id: data._id,
            remarks: "Deleted by " + data.name,
            appId: data.appId,
            token: Cache.getItem("otpToken")
        }
    }

    var endpoint = `${process.env.API_ENV}/app/production`

    await API.del('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => processAppAPIError(err))

    return result
}

// ADD APPLICATION DELEGATE
export const handleAddApplicationDelegate = async (params) => {

    let result = false
    let init = {
        body: {
            appId: params.data._id,
            ownerId: params.data.createdBy,
            email: params.email,
            name: params.name,
            username: params.username
        }
    }

    var endpoint = `${process.env.API_ENV}/delegate/post`

    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

// FETCH USERS DELEGATED TO THE APP
export const handleGetApplicationDelegate = async (params) => {
    let result
    let init = {
        body: {
            appId: params.data._id,

        }
    }

    var endpoint = `${process.env.API_ENV}/delegate/get`

    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

// FETCH DELEGATED APPS OF THE USER
// TODO: Switch to POST Method
export const handleGetDelegatedApplications = async (data) => {
    let result
    let init = {
        body: {
            email: data.email,
            type: "delegate",
            token: Cache.getItem("otpToken")
        }
    }

    var endpoint = `${process.env.API_ENV}/app/get`

    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => processAppAPIError(err))

    return result
}

// DELETE DELEGATED USERS
export const handleDeleteApplicationDelegate = async (params) => {
    let result
    let init = {
        body: params
    }

    var endpoint = `${process.env.API_ENV}/delegate`

    await API.del('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}


export const handleGetApiDetails = async (data) => {
    let result
    let init = {
        body: {
            token: Cache.getItem("otpToken")
        }
    }

    var endpoint = `${process.env.API_ENV}/app/api`

    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => processAppAPIError(err))

    return result
}

export const handleGetPrivateAllowed = async (useremail) => {
    console.log(useremail)
    let result
    let init = {
        body: {
            useremail: useremail,
        }
    }

    var endpoint = `${process.env.API_ENV}/privateapp/allowed`

    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

// -------------------
// | EVENT FUNCTIONS |
// -------------------
export const handleGetEventList = async (data) => {
    let result
    let init = {
        queryStringParameters: {
            type: "list"
        }
    }

    var endpoint = `${process.env.API_ENV}/event`

    await API.get('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

export const handleGetEventDetails = async (data) => {
    let result
    let init = {
        queryStringParameters: {
            type: "details",
            id: data.event_id
        }
    }

    var endpoint = `${process.env.API_ENV}/event`

    await API.get('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result
}

export const handleRegisterEvent = async (data) => {
    let result
    let init = {
        body: {
            event_id: data.eventId,
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            phone_number: data.phoneNumber,
            organization: data.organization
        }
    }

    var endpoint = `${process.env.API_ENV}/event/register`

    await API.post('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => {
            if (err.response.status == 401)
                result = err.response.data
            else
                console.log(err);
        })

    return result
}

export const handleAddUser = async (data) => {
    let result
    let init = {
        body: {
            email: data.email
        }
    }

    var endpoint = `${process.env.API_ENV}/user/add`

    await API.post('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => {
            if (err.response.status == 401)
                result = err.response.data
            else
                console.log(err);

        })

    return result
}

export const handleUpdateUser = async (data) => {
    let result
    let init = {
        body: {
            username: data
        }
    }

    var endpoint = `${process.env.API_ENV}/user/updatedb`

    await API.post('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => {
            if (err.response.status == 401)
                result = err.response.data
            else
                console.log(err);

        })

    return result
}

export const handleGetPrivateInvitationDetails = async (data) => {
    let result
    let init = {
        queryStringParameters: {
            event_id: data.event_id,
            invitation_code: data.invitation_code
        }
    }

    var endpoint = `${process.env.API_ENV}/event/private_invitations/details`

    await API.get('rdsApi', endpoint, init)
        .then((res) => {
            console.log(res)
            result = res
        })
        .catch(err => console.log(err))

    return result
}

export const handleInvitationResponse = async (data) => {
    let result
    let init = {
        body: {
            event_id: data.event_id,
            invitation_code: data.invitation_code,
            response: data.response
        }
    }

    var endpoint = `${process.env.API_ENV}/event/private_invitations/response`

    await API.post('rdsApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => {
            if (err.response.status == 401)
                result = err.response.data
            else
                console.log(err);

        })

    return result
}


// CUSTOM FUNCTIONS
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|<>='";:[\]\\\/]/g, '');
}

export const submitSalesLead =async (inputObj) =>{
    const endpoint = `${process.env.API_ENV}/netsclick/enquiry`
    let init = {
        body: inputObj
    }
    let flag = null
    await API.post('privateApi', endpoint, init)
        .then(async (resp) => {
            if (resp.status == 'success') {
                flag = resp
            }
        })
        .catch(err => {
            console.log(err)
            flag = err
        })
    return flag
}

export const netsclickAppRequestorStatus = async (username) => {
    var endpoint = `${process.env.API_ENV}/netsclick/requestor`
    let init = {
        queryStringParameters: {
            email: username
        }
    }
    let appStatus = null
    await API.get('privateApi', endpoint, init)
        .then(async (resp) => {
            console.log(resp)
            appStatus = resp
        })
        .catch(err => {
            console.log(err)
            return null
        })
    return appStatus
}
export const getNetsclickRefundStatus = async ( appID ) => {
    var endpoint = `${process.env.API_ENV}/netsclick/refund`
    let init = {
        queryStringParameters: {
            netsclickAppID: appID
        }
    }
    let appStatus = null
    await API.get('privateApi', endpoint, init)
        .then(async (resp) => {
            console.log(resp)
            appStatus = resp
        })
        .catch(err => {
            console.log(err)
            return null
        })
    return appStatus
}

export const getCommonCredentialsDetails = async (appID) => {
    var endpoint = `${process.env.API_ENV}/netsclick/common-credentials`
    let init = {
        queryStringParameters: {
            netsclickAppID: appID
        }
    }
    let appStatus = null
    await API.get('privateApi', endpoint, init)
        .then(async (resp) => {
            console.log(resp)
            appStatus = resp
        })
        .catch(err => {
            console.log(err)
            return null
        })
    return appStatus
}
export const getSignerInfo = async (inputObj) => {
    var endpoint = `${process.env.API_ENV}/netsclick/signers/files`
    let init = {
        queryStringParameters: inputObj
    }
    let flag = null
    await API.get('privateApi', endpoint, init)
        .then(async (resp) => {
            console.log("Success")
            flag = resp
        })
        .catch(err => {
            console.log(err)
            return false
        })
    return flag
}
export const validateCertExpiry = async (data) => {
    const endpoint = `${process.env.API_ENV}/netsclickapp/upload`;
    let parsedFiles = [];

    // Parse all files
    for (const file of data) {
        try {
            let parsedFile = await parseFile(file);
            console.log(parsedFile);
            let fileObject = { name : file.name , value : parsedFile}
            parsedFiles.push(fileObject);
        } catch (err) {
            console.log(`Error parsing file`);
        }
    }
    let init = {
        queryStringParameters: {
            "type" : "validate"
        },
        body: parsedFiles
    }
    try {
        let response = await API.post('privateApi', endpoint, init);
        return response
    } catch (err) {
        return null
    }
}


export const submitSignerDetailsInfo = async (inputObj) => {
   
    const endpoint = `${process.env.API_ENV}/netsclick/signers`
    let init = {
        queryStringParameters: inputObj
    }
    let flag = null
    await API.post('privateApi', endpoint, init)
        .then(async (resp) => {
            if (resp.status == 'success') {
                flag = resp
            }
        })
        .catch(err => {
            console.log(err)
            flag = err
        })
    return flag
}

export const getSnowREQStatus = async (inputObj) => {
    let endpoint = `${process.env.API_ENV}/snow/status`
    let response = null
    let init = {
        queryStringParameters: inputObj
    }
    await API.get('privateApi', endpoint, init)
        .then(async (res) => {
            console.log(" Response ")
            response = res
        })
        .catch(err => { 
            return false
        })
           
    return response
}
export const UploadDocstoS3 = async (inputObj, file) => {
    let response = null
    let { value, name , key  } = file
    const endpoint = `${process.env.API_ENV}/netsclickapp/upload`
    let env = inputObj.env
    let uploadType = inputObj.fileType
    let referenceNo = inputObj.ncAppID
    let path = `merchant/${env}/${uploadType}/${referenceNo}/`
    let parsedFile = await parseFile(value)
    let init = {
        queryStringParameters: {
            "type" : "upload",
            "fileType": key,
            "fileRef": uploadType,
            "s3FileName": name,
            "env": env,
            "path": path,
            "email": inputObj.email,
            "reqID": referenceNo,
            "fileStatus" : inputObj.fileStatus,
            "expDate" : value.expiryDate
        },
        body: parsedFile
    }
    await API.post('privateApi', endpoint, init)
        .then(async (res) => {
            response = res
            return res
        })
        .catch(err => {
            return err
        })
}
export const downloadObject = async (path, filename) => {
    var endpoint = `${process.env.API_ENV}/netsclick/download`
    let init = {
        queryStringParameters: {
            objPath: path
        }
    }
    console.log(init)
    await API.post('privateApi', endpoint, init)
        .then(async (url) => {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                filename,
            );
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
        .catch(err => {
            console.log(err)
            return false
        })
}
export const decryptAndDownloadFile = async (inputObj) => {
    var endpoint = `${process.env.API_ENV}/netsclick/files/download`
    let init = {
        body: inputObj
    }
    await API.post('privateApi', endpoint, init)
        .then(async (downloadRes) => {
            if (downloadRes && downloadRes.success) {
                
                const binaryImg = atob(downloadRes.base64File);

                const length = binaryImg.length;
                const arrayBuffer = new ArrayBuffer(length);
                const uintArray = new Uint8Array(arrayBuffer);

                for (let i = 0; i < length; i++) {
                    uintArray[i] = binaryImg.charCodeAt(i);
                }

                const fileBlob = new Blob([uintArray], { type: '' });

                const downloadBlob = function (blob, name = 'file.txt') {
                    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
                    const blobUrl = URL.createObjectURL(blob);

                    // Create a link element
                    const link = document.createElement("a");

                    // Set link's href to point to the Blob URL
                    link.href = blobUrl;
                    link.download = name;

                    // Append link to the body
                    document.body.appendChild(link);

                    // Dispatch click event on the link
                    // This is necessary as link.click() does not work on the latest firefox
                    link.dispatchEvent(
                        new MouseEvent('click', {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        })
                    );

                    // Remove link from body
                    document.body.removeChild(link);
                }

                downloadBlob(fileBlob, downloadRes.fileName);
            }
        })
        .catch(err => {
            console.log(err)
            return false
        })
}
export const getSalesUsers = async () => {
    
    var endpoint = `${process.env.API_ENV}/netsclick/salesrep`
   
    let data = null
    await API.get('privateApi', endpoint)
        .then(async (res) => { 
            data = res
        })
        .catch(err => {
            console.log(err)
            return false
        })
    return data

}

export const removeSignersCertFile = async (data) => {

    const endpoint = `${process.env.API_ENV}/netsclick/signers/delete`
    let init = {
        body: data
    }
    let result = null
    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
            console.log("Success")
        })
        .catch(err => console.log(err))

    return result

}

export const submitNetsclickRequestorApp = async (data) => {

    const endpoint = `${process.env.API_ENV}/netsclick/requestor`
    let init = {
        body: data
    }
    let result = null
    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => console.log(err))

    return result

}
export const getIntegrationStatus = async (inputObj) => {
    var endpoint = `${process.env.API_ENV}/netsclick/integration`
    let init = {
        queryStringParameters: inputObj
    }
    let appStatus = null
    await API.get('privateApi', endpoint, init)
        .then(async (resp) => {
            appStatus = resp
        })
        .catch(err => {
            console.log(err)
            return null
        })
    return appStatus
}
export const updateIntegrationValues = async (inputObj) => {

    const endpoint = `${process.env.API_ENV}/netsclick/integration`
    let init = {
        body: inputObj
    }
    let result = null
    await API.post('privateApi', endpoint, init)
        .then((res) => {
            result = res
        })
        .catch(err => {
            return false
        })

    return result

}

export const getSDKList = async (env) => {
    let endpoint = `${process.env.API_ENV}/netsclick/sdk/list`
    let response = null
    let init = {
        queryStringParameters: {
            env: env
        }
    }
    await API.get('privateApi', endpoint, init)
        .then(async (res) => {
            response = res
        })
        .catch(err => {
            return false
        })
    return response
}
export const getVkeyAssetsDetails = async (inputObj) => {
    let endpoint = `${process.env.API_ENV}/netsclick/vkeyassets`
    let response = null
    let init = {
        queryStringParameters: inputObj
    }
    await API.get('privateApi', endpoint, init)
        .then(async (res) => {
            response = res
        })
        .catch(err => {
            return false
        })
    return response
}
export const getMidTidDetails = async (inputObj) => {
    let endpoint = `${process.env.API_ENV}/netsclick/midtid`
    let response = null
    let init = {
        queryStringParameters: inputObj
    }
    await API.get('privateApi', endpoint, init)
        .then(async (res) => {
            response = res
        })
        .catch(err => {
            return false
        })
    return response
}
export const getSFTPDetails = async (inputObj) => {
    let endpoint = `${process.env.API_ENV}/netsclick/sftp`
    let response = null
    let init = {
        queryStringParameters: inputObj
    }
    await API.get('privateApi', endpoint, init)
        .then(async (res) => {
            response = res
        })
        .catch(err => {
            return false
        })
    return response
}


export const getMAPHPPKeys = async (inputObj) => {
    let endpoint = `${process.env.API_ENV}/netsclick/map-hpp-keys`
   
    let response = null
    let init = {
        queryStringParameters: inputObj
    }
    await API.get('privateApi', endpoint, init)
        .then(async (res) => {
            response = res
        })
        .catch(err => {
            return false
        })
    return response
}

export const sendUserQuery = async (data) => {
    let endpoint = `${process.env.API_ENV}/netsclick/sales/query`
    let init = {
        body: data
    }
    let flag = null
    await API.post('rdsApi', endpoint, init)
        .then(async (resp) => {
            if (resp.status == 'success') {
                flag = true
            }
        })
        .catch(err => {
            console.log(err)
            flag = null
        })
    return flag
}
export const sendMerchantQuery = async (data) => {
    let endpoint = `${process.env.API_ENV}/netsclick/merchant/query`
    let init = {
        body: data
    }
    let flag = null
    await API.post('privateApi', endpoint, init)
        .then(async (resp) => {
            if (resp.status == 'success') {
                flag = true
            }
        })
        .catch(err => {
            console.log(err)
            flag = null
        })
    return flag
}


// -------------------
// | EMAIL FUNCTIONS |
// -------------------

export const passwordChangeNotification = async (email) => {
    
    let endpoint = `${process.env.API_ENV}/email/passwordChange`
  
    let init = {
        body: email
    }
    let flag = null
    await API.post('privateApi', endpoint, init)
        .then(async (resp) => {
            if (resp.status == 'success') {
                flag = true
            }
        })
        .catch(err => {
            console.log(err)
            flag = null
        })
    return flag
}

function processAppAPIError(err, disableReload) {
    console.log(err)
    if (err.message.includes('status code 403')) {
        Cache.setItem("otpToken", "")
        Cache.setItem("ma", 0)
        navigate('/account/mfauth')
    } else if (!disableReload && err.message.includes('status code 502')) {
        window.location.reload()
    }
}

const parseFile = function (file) {
    console.log(file)
    var reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onerror = () => { reader.abort(); reject(new Error("Error parsing file")); }
        reader.onload = function () {

            //This will result in an array that will be recognized by C#.NET WebApi as a byte[]
            let bytes = Array.from(new Uint8Array(this.result));

            //if you want the base64encoded file you would use the below line:
            let base64StringFile = btoa(bytes.map((item) => String.fromCharCode(item)).join(""));

            //Resolve the promise with your custom file structure
            resolve({
                // bytes: bytes,
                base64StringFile: base64StringFile,
                fileName: file.name,
                fileType: file.type,
                fileSize: file.size
            });
        }
        reader.readAsArrayBuffer(file);
    });
}