import React, { Component } from "react"
import {  navigate } from "@reach/router"
import { isLoggedIn, logout, isAuthenticated } from "../services/auth"
import { MdLock, MdShare, MdExitToApp, MdSearch } from "react-icons/md";
import { Navbar, NavbarToggler, Collapse, UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu, NavbarBrand, Nav, NavItem, NavLink, Input, Form, Dropdown } from 'reactstrap';

// import logo from '../images/dev-logo.png'
import logo from '../images/dev-logo1.png'
// import logo2 from '../images/dev-logo2.png'

export class DefaultNav extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      status : false,
      isOpen: false,
      search : "",
      dropdownOpen: false,
      supportOpen: false,
      profileOpen: false,
      apiOpen:false,
    };

    // Controlled States for NavBar MouseOver - Support
    this.onSupportEnter = this.onSupportEnter.bind(this)
    this.onSupportLeave = this.onSupportLeave.bind(this)
    this.navSupport = this.navSupport.bind(this)
    
    // Controlled States for NavBar MouseOver - Login
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.navLogin = this.navLogin.bind(this)
    
    // Controlled States for NavBar MouseOver - Profile
    this.onProfileEnter = this.onProfileEnter.bind(this);
    this.onProfileLeave = this.onProfileLeave.bind(this);
    this.navProfile = this.navProfile.bind(this)
    
    // Controlled States for NavBar MouseOver - API Library
    this.onApiEnter = this.onApiEnter.bind(this);
    this.onApiLeave = this.onApiLeave.bind(this);
    this.navApi = this.navApi.bind(this)
  }

  async componentDidMount(){
    if(isLoggedIn()){
      isAuthenticated();
    }

    this.setState({
      status : isLoggedIn()
    })
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  // Support
  navSupport(){
    window.location.replace('/support/faq/')
  }

  onSupportEnter() {
    this.setState({supportOpen: true});
  }

  onSupportLeave() {
    this.setState({supportOpen: false});
  }

  // Profile
  navProfile(){
    window.location.replace('/account/profile/')
  }

  onProfileEnter() {
    this.setState({profileOpen: true});
  }

  onProfileLeave() {
    this.setState({profileOpen: false});
  }

  // Api
  navApi(){
    window.location.replace('/docs/guide/')
  }

  onApiEnter() {
    this.setState({apiOpen: true});
  }

  onApiLeave() {
    this.setState({apiOpen: false});
  }

  // Login
  navLogin() {
    window.location.replace('/signin/')
  }

  onMouseEnter() {
    this.setState({dropdownOpen: true});
  }

  onMouseLeave() {
    this.setState({dropdownOpen: false});
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name] : event.target.value
    })
  }

  submitHandler = (event) => {
    event.preventDefault()
    navigate('/search/', {state : { data : this.state.search }})
  }

  render() {
    return (
      <Navbar color="faded" light expand="md" style={{padding: `0.5rem 0 0`}}>
        <NavbarBrand href="/">
          <img src={logo} alt="Nets Developer Portal"
            style={{
              maxWidth: `200px`,
              marginBottom : `0.5rem`
            }}/>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/" style={{fontWeight:"bolder"}}>Home</NavLink>
            </NavItem>

          {/* Account */}
            {this.state.status ? (
              <Dropdown onMouseOver={this.onProfileEnter} onMouseLeave={this.onProfileLeave} isOpen={this.state.profileOpen}  nav inNavbar toggle={(event) => {}}>
                <DropdownToggle nav caret style={{fontWeight:"bolder"}}>
                  My Account
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem

                    href="/account/profile/"
                    style={{fontWeight:"bolder"}}
                    >
                    Profile
                  </DropdownItem>
                  <DropdownItem
                    style={{fontWeight:"bolder"}}
                    href="/account/mfauth/"
                    >
                    Application
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : null }

          {/* API Library */}
            {
              <Dropdown onMouseOver={this.onApiEnter} onMouseLeave={this.onApiLeave} isOpen={this.state.apiOpen}  nav inNavbar toggle={(event) => {}}>
                <DropdownToggle nav caret style={{fontWeight:"bolder"}}>
                  Product
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    style={{fontWeight:"bolder"}}
                    href="/product/netsclick/requestor"
                    >
                    NETS Click
                  </DropdownItem>
                  <DropdownItem
                      style={{fontWeight:"bolder"}}
                    href="/product/eNetsQR/"
                    >
                    eNETS/NETS QR
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            }
            

            <NavItem>
              <NavLink href="/guide/"  style={{fontWeight:"bolder"}}>Portal Guide</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/events/"  style={{fontWeight:"bolder"}}>Events</NavLink>
            </NavItem>
            <Dropdown onMouseOver={this.onSupportEnter} onMouseLeave={this.onSupportLeave} isOpen={this.state.supportOpen}  nav inNavbar toggle={(event) => {}}>
                <DropdownToggle nav caret  style={{fontWeight:"bolder"}}>
                  Support
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                      style={{fontWeight:"bolder"}}
                    href="/support/faq/"
                      >
                      FAQs
                  </DropdownItem>
                  <DropdownItem
                      style={{fontWeight:"bolder"}}
                    href="/support/schedule/"
                      >
                      Maintenance Schedule
                  </DropdownItem>
                {/* Enquiry */}
                  {this.state.status ? (
                    <DropdownItem
                      style={{fontWeight:"bolder"}}
                      href="/support/enquiry/">
                        Enquiry
                    </DropdownItem>
                  ) : null}
                  
                  <DropdownItem
                        style={{fontWeight:"bolder"}}
                      href="/support/forum/"
                      >
                      Forum
                  </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>

          {this.state.status ? (
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown  nav inNavbar>
                <DropdownToggle nav style={{fontWeight:"bolder"}}>
                  <MdSearch style={{ verticalAlign: 'text-top'}}/>
                  Search
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>
                  <Form onSubmit={this.submitHandler}>
                    <Input
                      style={{minWidth:`200px`}}
                      type="text"
                      placeholder="Search..."
                      name="search"
                      value={this.state.search}
                      onChange={this.handleChange}
                      />
                    </Form>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href="/refer/" style={{fontWeight:"bolder"}}>
                  <MdShare style={{ verticalAlign: 'text-top'}}/>
                  Refer a Developer
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/"
                  style={{fontWeight:"bolder"}}
                  onClick={
                    event => {
                      event.preventDefault()
                      logout(() => navigate(`/signin/`))
                    }}
                  >
                    <MdExitToApp style={{ verticalAlign: 'text-top'}}/>
                      Sign Out
                </NavLink>
              </NavItem>
            </Nav>
          ) : (
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown  nav inNavbar>
                <DropdownToggle nav style={{fontWeight:"bolder"}}>
                  <MdSearch style={{ verticalAlign: 'text-top'}}/>
                  Search
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header >
                  <Form onSubmit={this.submitHandler}>
                    <Input
                      style={{minWidth:`200px`}}
                      type="text"
                      placeholder="Search..."
                      name="search"
                      value={this.state.search}
                      onChange={this.handleChange}
                      />
                    </Form>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href="/refer/" style={{fontWeight:"bolder"}}>
                  <MdShare style={{ verticalAlign: 'text-top'}}/>
                  Refer a Developer
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/signin/" style={{fontWeight:"bolder"}}>
                  <MdLock style={{ verticalAlign: 'text-top'}}/>
                  Sign Up / Sign In
                </NavLink>
              </NavItem>
            </Nav>
          )}
        </Collapse>
      </Navbar>
    )
  }
}
